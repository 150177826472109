import React from 'react';
import Header from '../components/inc_header';

const OnlineDGSKursu = () => {
  return (
    <div>
      <Header />

      <div className="rbt-progress-parent">
        <svg className="rbt-back-circle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>

      <div className="rbt-banner-area rbt-banner-8 variation-01 bg_image bg_image--9">
        <div className="wrapper w-100">
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 order-1 order-lg-1">
                <div className="content">
                  <div className="inner">
                    <div className="rbt-badge-group justify-content-start">
                      <span className="meta-text d-flex align-items-center"><span className="icon">🎬</span> Canlı Ders</span>
                      <a href="#" className="rbt-badge-2">
                        <div className="image">
                          <img src="assets/images/testimonial/client-02.png" alt="Education Images" />
                        </div>
                        Başarı İçin Gereken Tek Şey: <strong>Doğru Eğitim</strong>
                      </a>
                    </div>
                    <h1 className="title">DGS Kursu</h1>
                    <p className="description has-medium-font-size mt--20">
                      Uzman eğitmenlerle hazırlanmış içerikler ve interaktif derslerle DGS sınavlarında başarıya ulaşmanın anahtarı burada!
                    </p>
                    <div className="slider-btn rbt-button-group justify-content-start">
                      <a className="rbt-btn btn-gradient radius-round hover-icon-reverse" href="kurskayit.php">
                        <span className="icon-reverse-wrapper">
                          <span className="btn-text">Kursa Kayıt Ol</span>
                          <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                          <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                        </span>
                      </a>
                      <a className="rbt-btn radius-round hover-icon-reverse btn-white" href="iletisim.php">
                        <span className="icon-reverse-wrapper">
                          <span className="btn-text">İletişim</span>
                          <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                          <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt--50">
        <div className="row">
          <h3>ÖRNEK DERS & SORU ÇÖZÜM VİDEOLARIMIZ</h3>
          <div className="col-lg-6 col-md-6">
            <div className="video-wrapper">
              <h4>Örnek Matematik Ders Videosu</h4>
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/4X4gQ5qTwCA" frameBorder="0" allowFullScreen></iframe>
            </div>
          </div>
        </div>

        <div className="row mt--30">
          <div className="col-lg-6 col-md-6">
            <div className="video-wrapper">
              <h4>Örnek Türkçe Ders Videosu</h4>
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/IRSDuVR0kCc" frameBorder="0" allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="rbt-course-feature-box rbt-shadow-box details-wrapper mt--30" id="details">
            <div className="row g-5">
              <div className="col-lg-6">
                <div className="section-title">
                  <h4 className="rbt-title-style-3 mb--20">Eğitim İçeriği</h4>
                </div>
                <ul className="rbt-list-style-1">
                  <li><i className="feather-check"></i>📚 Toplam 400 saat ONLINE (CANLI DERS)</li>
                  <li><i className="feather-check"></i>👍5 adet kaynak</li>
                  <li><i className="feather-check"></i>👍Tüm konulara ait konu anlatımı</li>
                  <li><i className="feather-check"></i>👍Çıkmış sınav sorularının çözümü</li>
                  <li><i className="feather-check"></i>👍 Türkçe : 80 Saat</li>
                  <li><i className="feather-check"></i>👍 Matematik : 260 Saat</li>
                  <li><i className="feather-check"></i>👍 Geometri : 30 Saat</li>
                  <li><i className="feather-check"></i>👍 Sayısal, Sözel Mantık : 30 Saat</li>
                  <li><i className="feather-check"></i>📚 Toplam 400 saat ONLINE (CANLI DERS)</li>
                </ul>
              </div>

              <div className="col-lg-6">
                <div className="section-title">
                  <h4 className="rbt-title-style-3 mb--20">Online DGS Kursu</h4>
                </div>
                <ul className="rbt-list-style-1">
                  <li><i className="feather-check"></i>👍Soru çözüm stratejileri</li>
                  <li><i className="feather-check"></i>👍PDF doküman desteği</li>
                  <li><i className="feather-check"></i>👍10 adet online deneme</li>
                  <li><i className="feather-check"></i>👍Öğretmenin ders notları</li>
                  <li><i className="feather-check"></i>👍DGS kampı</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rbt-about-area about-style-1 bg-color-white rbt-section-gap">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <div className="inner pl--50 pl_sm--5">
                <div className="content text-start">
                  <span className="rbt-badge-6 bg-primary-opacity">Online DGS Kurs Kaydı</span>
                  <h2>Ödeme Seçenekleri</h2>
                  <ul>
                    <li><strong>Kredi Kartı ile Ödeme:</strong></li>
                    <ul>
                      <li><strong>Online DGS Kurs Ödeme Linki:</strong> <a href="https://www.paytr.com/link/0bPrxow" target="_blank" rel="noopener noreferrer">Ödeme Yap</a></li>
                    </ul>
                    <li><strong>Banka Havalesi ile Ödeme:</strong> Kredi kartı dışında banka havalesi ile ödeme yapmak isteyenler, aşağıdaki banka hesabına kurs ücretini havale yapabilirler. Havale yapıldıktan sonra lütfen makbuzu bizimle paylaşın.</li>
                    <ul>
                      <li><strong>Banka Adı:</strong> İş Bankası</li>
                      <li><strong>Ad Soyad:</strong> Gürdal Kurt</li>
                      <li><strong>IBAN:</strong> TR600006400000142130793029</li>
                      <li><strong>Açıklama:</strong> Lütfen açıklama kısmına adınızı, soyadınızı, telefon numaranızı ekleyin.</li>
                    </ul>
                  </ul>
                  <p>Herhangi bir sorunuz olursa, bizimle iletişime geçmekten çekinmeyin. Başarılar dileriz!</p>

                  <div className="row">
                    <h1>Kurs Kayıt ve Ödeme Süreci</h1>
                    <p>KPSS, DGS, ALES, YDS gibi kurslarımıza kayıt olmak çok kolay! Aşağıda belirtilen adımları izleyerek kurs kaydınızı tamamlayabilirsiniz:</p>
                    <p><strong>1. Kayıt İşlemi:</strong> Kayıt olmak istediğiniz kursun ödeme linkine tıklayarak kurs ücreti ödemesini gerçekleştirin veya banka havalesi ile aşağıdaki adımları izleyerek ödemenizi yapın. Tüm kredi kartlarına 3, 6, 12 taksit imkanı sağlanmaktadır. Ödeme işleminizin başarıyla tamamlanmasının ardından, kayıt işleminiz de otomatik olarak tamamlanmış olacaktır.</p>
                    <p><strong>2. Kullanıcı Adı ve Şifre:</strong> Ödemeniz alındıktan sonra, sistem tarafından size kullanıcı adı ve şifre bilgileri gönderilecektir. Bu bilgilerle platformumuza giriş yapabilirsiniz.</p>
                    <p><strong>3. Ders Videoları ve Dökümanlar:</strong> Giriş yaptıktan sonra, canlı derslerin kayıtlarını izleyebilir ve kurs materyallerine, dökümanlarına erişim sağlayabilirsiniz. Böylece, kurs boyunca dersleri dilediğiniz zaman takip edebilir ve öğrenim sürecinizi kolaylaştırabilirsiniz.</p>
                    <p><strong>4. WhatsApp Grubu ve Canlı Ders Linkleri:</strong> Kurs kaydınızın tamamlanmasının ardından sizi WhatsApp grubumuza ekleyeceğiz. Canlı ders giriş linkleri ve diğer önemli duyurular bu grup üzerinden paylaşılacaktır.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineDGSKursu;
