import React from "react";

const Dokumanlar = () => {
  return (
    <div className="p-4 border rounded shadow-sm">
      <h1 style={{ fontSize: "26px", fontWeight: "bold", marginBottom: "20px" }}>PDF Dökümanlar</h1>
      
      <ul style={{ fontSize: "20px", lineHeight: "2" }}>
        <li>
          <a
            href="URL_KPSS_PDF_DOKUMANLAR"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "blue", textDecoration: "none", fontWeight: "bold" }}
          >
            KPSS PDF Dökümanlar
          </a>
        </li>
        <ul style={{ marginLeft: "20px", fontSize: "18px" }}>
          <li>
            <a
              href="URL_KPSS_KONU_ANLATIM"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "none" }}
            >
              KPSS Konu Anlatım Kitapları
            </a>
          </li>
          <li>
            <a
              href="URL_KPSS_SORU_BANKASI"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "none" }}
            >
              KPSS Soru Bankası Kitapları
            </a>
          </li>
          <li>
            <a
              href="URL_KPSS_YAPRAK_TESTLER"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "none" }}
            >
              KPSS Yaprak Testler
            </a>
          </li>
        </ul>

        <li>
          <a
            href="URL_DGS_PDF_DOKUMANLAR"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "blue", textDecoration: "none", fontWeight: "bold" }}
          >
            DGS PDF Dökümanlar
          </a>
        </li>
        <ul style={{ marginLeft: "20px", fontSize: "18px" }}>
          <li>
            <a
              href="URL_DGS_KONU_ANLATIM"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "none" }}
            >
              DGS Konu Anlatım Kitapları
            </a>
          </li>
          <li>
            <a
              href="URL_DGS_SORU_BANKASI"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "none" }}
            >
              DGS Soru Bankası Kitapları
            </a>
          </li>
          <li>
            <a
              href="URL_DGS_YAPRAK_TESTLER"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "none" }}
            >
              DGS Yaprak Testler
            </a>
          </li>
        </ul>

        <li>
          <a
            href="URL_ALES_PDF_DOKUMANLAR"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "blue", textDecoration: "none", fontWeight: "bold" }}
          >
            ALES PDF Dökümanlar
          </a>
        </li>
        <ul style={{ marginLeft: "20px", fontSize: "18px" }}>
          <li>
            <a
              href="URL_ALES_KONU_ANLATIM"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "none" }}
            >
              ALES Konu Anlatım Kitapları
            </a>
          </li>
          <li>
            <a
              href="URL_ALES_SORU_BANKASI"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "none" }}
            >
              ALES Soru Bankası Kitapları
            </a>
          </li>
          <li>
            <a
              href="URL_ALES_YAPRAK_TESTLER"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "none" }}
            >
              ALES Yaprak Testler
            </a>
          </li>
        </ul>

        <li>
          <a
            href="URL_YDS_PDF_DOKUMANLAR"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "blue", textDecoration: "none", fontWeight: "bold" }}
          >
            YDS PDF Dökümanlar
          </a>
        </li>
        <ul style={{ marginLeft: "20px", fontSize: "18px" }}>
          <li>
            <a
              href="URL_YDS_GRAMER"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "none" }}
            >
              Gramer Konu Anlatımı ve Dil Bilgisi Konu Testleri
            </a>
          </li>
          <li>
            <a
              href="URL_YDS_KELIME_BILGISI"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "none" }}
            >
              Kelime Bilgisi Kitabı
            </a>
          </li>
          <li>
            <a
              href="URL_YDS_OKUMA"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "none" }}
            >
              YDS Okuma Kitabı
            </a>
          </li>
          <li>
            <a
              href="URL_YDS_SORU_BANKASI"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "none" }}
            >
              İngilizce Soru Bankası Kitabı
            </a>
          </li>
        </ul>
      </ul>
    </div>
  );
};

export default Dokumanlar;
